<template>
  <v-container fluid>
    <v-card>
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="pieChartPic"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              THEO DÕI XẾP LOẠI, KỶ LUẬT NHÂN SỰ</v-list-item-title
            >
            <v-list-item-subtitle
              >Danh sách phân loại đánh giá nhân sự</v-list-item-subtitle
            >
          </v-list-item-content>

          <div class="pt-3">
            <v-btn color="#33691E" class="mr-4" @click="taiFIleMau">
              <v-icon color="white"> mdi-arrow-down</v-icon>
              <span style="color: white" class="btn-add">Tải File Mẫu</span>
            </v-btn>
            <v-btn color="secondary" @click="uploadExcel()">
              <v-icon color="white" v-if="!loadingImport">mdi-arrow-up</v-icon>
              <v-progress-circular
                v-else
                indeterminate
                color="red"
              ></v-progress-circular>
              <span style="color: white" class="btn-add">Nhập từ excel</span>
            </v-btn>
          </div>
          <input
            name="file"
            ref="upload-image"
            style="display: none"
            type="file"
            @change="handleUpload($event)"
          />
        </v-layout>
      </v-list-item>
    </v-card>
    <v-card class="mb-1 mt-3">
      <v-card-title class="pa-6 pb-4">
        <div class="black--text">Danh sách xếp loại hàng tháng</div>
        <v-spacer></v-spacer>
        <div style="width: 600px" class="mr-4">
          <v-row>
            <v-col cols="7">
              <v-text-field
                append-icon="mdi-magnify"
                v-model="search"
                label="Tìm kiếm"
                clearable
                single-line
                hide-details
              ></v-text-field
            ></v-col>
            <v-col cols="5">
              <v-menu
                ref="menu"
                v-model="showDate"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    prepend-icon="mdi-calendar"
                    placeholder="Chọn thời gian"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showDate = false">
                    Hủy
                  </v-btn>
                  <v-btn text color="primary" @click="changeDate()"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :loading="loading"
        class="elevation-1"
        loading-text="Đang tải dữ liệu ..."
      >
        <template v-slot:[`item.name`]="{ item }">
          <div @click="showNhanVien(item.id)" style="cursor: pointer">
            <v-avatar color="indigo" size="28">
              <img
                v-if="item.url_image"
                :src="imageEndpoint + item.url_image"
                alt="ManhLe"
              />
              <span style="color: white" v-else-if="item && item.name">{{
                item.name.charAt(0).toUpperCase()
              }}</span>
              <v-icon v-else dark>mdi-account</v-icon>
            </v-avatar>
            <span class="ml-3" style="font-size: 16px">{{ item.name }}</span>
          </div>
        </template>
        <template v-slot:[`item.gioi_tinh`]="{ item }">
          {{ item.gioi_tinh ? "Nam" : "Nữ" }}
        </template>
        <template v-slot:[`item.thoi_gian`]="">
          {{ time }}
        </template>
        <template v-slot:[`item.muc_dong`]="{ item }">
          <b>
            {{
              new Intl.NumberFormat({ style: "currency" }).format(
                item.muc_dong.muc_dong
              )
            }}
            Vnđ
          </b>
        </template>
      </v-data-table>
    </v-card>
    <div class="pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="changePage"
        :total-visible="10"
        >></v-pagination
      >
    </div>
  </v-container>
</template>
<script>
import pieChartPic from "@/assets/images/piechart.svg";
import { listDanhGia, importDanhGia } from "@/api/nhanvien";
import { debounce } from "lodash";

export default {
  data: () => ({
    pieChartPic,
    tableData: [],
    showDate: false,
    phong_ban_id: null,
    nhom_to_id: null,
    chuc_vu_id: null,
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    search: null,
    loading: false,
    loadingImport: false,
    date: "",
    time: "",
    headers: [
      { text: "Họ tên", value: "name", sortable: false, width: 250 },
      {
        text: "Phòng ban",
        align: "start",
        sortable: false,
        value: "phong_ban",
      },
      {
        text: "Chức vụ",
        align: "start",
        sortable: false,
        value: "chuc_vu",
      },
      {
        text: "Nhóm tổ",
        align: "start",
        sortable: false,
        value: "nhom_to",
      },
      {
        text: "Thời gian",
        align: "start",
        value: "thoi_gian",
      },
      {
        text: "Xếp loại",
        align: "start",
        value: "xepLoai.xep_loai",
      },
      {
        text: "Ghi chú",
        align: "start",
        value: "xepLoai.ghi_chu",
      },
    ],
  }),
  mounted() {
    this.getDateTime();
    this.getData();
  },
  watch: {
    search: debounce(async function (val) {
      this.loading = true;
      this.page = 1;
      let data = await listDanhGia({
        perPage: this.itemsPerPage,
        search: val,
        date: this.date,
      });
      this.loading = false;
      this.tongSoNguoi = data.meta.total;
      if (data) {
        this.tableData = data.data;
        this.pageCount = data.meta.last_page;
      } else {
        this.tableData = [];
        this.pageCount = 0;
      }
    }, 300),
  },
  methods: {
    async getData() {
      this.loading = true;
      let data = await listDanhGia({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
        date: this.date,
      });
      this.loading = false;
      this.tongSoNguoi = data.meta.total;
      if (data) {
        this.tableData = data.data;
        this.pageCount = data.meta.last_page;
      } else {
        this.tableData = [];
        this.pageCount = 0;
      }
    },
    taiFIleMau() {
      window.location.href = '/files/danhgia.csv'
    },
    async handleUpload(e) {
      let files = e.target.files;
      let data = new FormData();
      data.append("file", files[0]);
      var filePath = files[0].name.split(".").pop(); //lấy định dạng file
      var dinhDangChoPhep = ["xls", "xlsx", "csv"]; //các tập tin cho phép
      const isLt2M = files[0].size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.form.fileList.pop();
        this.$toast.warning("Kích thước file ảnh tối đa 20Mb!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return false;
      }
      if (!dinhDangChoPhep.find((el) => el == filePath.toLowerCase())) {
        this.loadingUpload = false;
        this.listLoading = false;
        this.iconUpload = "el-icon-bottom";
        this.$toast.warning("Tập tin không hợp lệ!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      try {
        this.loadingImport = true;
        this.loading = true;
        await importDanhGia(data);
        this.$toast.info("Thành công!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.loadingImport = false;
        this.loading = false;
        this.getData();
      } catch (error) {
        this.loadingImport = false;
        this.loading = false;
        console.log(error);
      }
      this.$refs["upload-image"].value = null;
    },
    uploadExcel() {
      this.$refs["upload-image"].click();
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    changeDate() {
      this.$refs.menu.save(this.date);
      this.page = 1;
      this.getDateTime();
      this.getData();
    },

    getDateTime() {
      let timenow = this.date ? new Date(this.date) : new Date();
      let month = timenow.getMonth() + 1;
      let year = timenow.getFullYear();
      this.time = "Tháng " + month + " Năm " + year;
      this.date = this.date ? this.date : year + "-" + month;
    },
    showMucDong(data) {
      let mucDong = data.find((el) => {
        if (el.ket_thuc) {
          return (
            new Date(el.ket_thuc) > new Date(this.date) &&
            new Date(el.bat_dau) <= new Date(this.date)
          );
        } else {
          return (
            new Date(el.bat_dau) <= new Date(this.date) &&
            new Date() > new Date(this.date)
          );
        }
      });
      if (mucDong) {
        let res = new Intl.NumberFormat({ style: "currency" }).format(
          mucDong.muc_dong
        );
        return res + " VnĐ";
      } else {
        return null;
      }
    },
    showNhanVien(id) {
      this.$router.push("/nhansu/thongtinnhanvien/" + id);
    },
  },
};
</script>